import revive_payload_client_o3saNuwel3 from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.1.0_drizzle-orm@0.32.2_postgres@3.4.4__iored_nxnhzgqxcx2xwldd3zp2zpvli4/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_pZ5Vx1eguY from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.1.0_drizzle-orm@0.32.2_postgres@3.4.4__iored_nxnhzgqxcx2xwldd3zp2zpvli4/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_kTUBRQ3KJP from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.1.0_drizzle-orm@0.32.2_postgres@3.4.4__iored_nxnhzgqxcx2xwldd3zp2zpvli4/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_bonNqqnpSj from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.1.0_drizzle-orm@0.32.2_postgres@3.4.4__iored_nxnhzgqxcx2xwldd3zp2zpvli4/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_SPc4D4nemH from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.1.0_drizzle-orm@0.32.2_postgres@3.4.4__iored_nxnhzgqxcx2xwldd3zp2zpvli4/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_g5tsfhK2Vl from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.1.0_drizzle-orm@0.32.2_postgres@3.4.4__iored_nxnhzgqxcx2xwldd3zp2zpvli4/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_utQdr6mBAJ from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.1.0_drizzle-orm@0.32.2_postgres@3.4.4__iored_nxnhzgqxcx2xwldd3zp2zpvli4/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_1HfiARuyLq from "/app/node_modules/.pnpm/@pinia+nuxt@0.5.3_magicast@0.3.4_rollup@4.20.0_vue@3.4.36/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_oBzw2koiGU from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.1.0_drizzle-orm@0.32.2_postgres@3.4.4__iored_nxnhzgqxcx2xwldd3zp2zpvli4/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import apexcharts_client_cuW7qbuNm0 from "/app/app/plugins/apexcharts.client.ts";
import easy_data_table_Z4gy48bWJ1 from "/app/app/plugins/easy-data-table.ts";
import vuetify_OSUbI9lY7S from "/app/app/plugins/vuetify.ts";
export default [
  revive_payload_client_o3saNuwel3,
  unhead_pZ5Vx1eguY,
  router_kTUBRQ3KJP,
  payload_client_bonNqqnpSj,
  navigation_repaint_client_SPc4D4nemH,
  check_outdated_build_client_g5tsfhK2Vl,
  chunk_reload_client_utQdr6mBAJ,
  plugin_vue3_1HfiARuyLq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_oBzw2koiGU,
  apexcharts_client_cuW7qbuNm0,
  easy_data_table_Z4gy48bWJ1,
  vuetify_OSUbI9lY7S
]