import { default as assetswCdsoq0lCPMeta } from "/app/app/pages/alerts/assets.vue?macro=true";
import { default as energyWV4vF2eInLMeta } from "/app/app/pages/alerts/energy.vue?macro=true";
import { default as environmentXUkzzR0NmYMeta } from "/app/app/pages/alerts/environment.vue?macro=true";
import { default as overalla5qIEgPzTlMeta } from "/app/app/pages/alerts/overall.vue?macro=true";
import { default as alertsxP8XTK7sgKMeta } from "/app/app/pages/alerts.vue?macro=true";
import { default as assetsQwSFeFIck1Meta } from "/app/app/pages/analytics/assets.vue?macro=true";
import { default as eia8WCwqmCLxNMeta } from "/app/app/pages/analytics/eia.vue?macro=true";
import { default as emsGHoP9DgEdQMeta } from "/app/app/pages/analytics/ems.vue?macro=true";
import { default as hcmh8vcjzdn21Meta } from "/app/app/pages/analytics/hcm.vue?macro=true";
import { default as mfg44OWjSsTpGMeta } from "/app/app/pages/analytics/mfg.vue?macro=true";
import { default as analyticsc7VTttKtEKMeta } from "/app/app/pages/analytics.vue?macro=true";
import { default as indexJVtWzqxUaHMeta } from "/app/app/pages/building/index.vue?macro=true";
import { default as alert0zHn65Tm68Meta } from "/app/app/pages/configuration/alert.vue?macro=true";
import { default as devicehJ8WJKBWtQMeta } from "/app/app/pages/configuration/device.vue?macro=true";
import { default as location54CtY3Mt4HMeta } from "/app/app/pages/configuration/location.vue?macro=true";
import { default as userRvFxSt4HWcMeta } from "/app/app/pages/configuration/user.vue?macro=true";
import { default as configurationsibTRTMELvMeta } from "/app/app/pages/configuration.vue?macro=true";
import { default as assets2inSRkqQiTMeta } from "/app/app/pages/dashboard/assets.vue?macro=true";
import { default as eiayWXcl0j8YGMeta } from "/app/app/pages/dashboard/eia.vue?macro=true";
import { default as emsaMB6Lcpfi5Meta } from "/app/app/pages/dashboard/ems.vue?macro=true";
import { default as hcmZ7Z4VhVRMjMeta } from "/app/app/pages/dashboard/hcm.vue?macro=true";
import { default as mfgums5uSFiMVMeta } from "/app/app/pages/dashboard/mfg.vue?macro=true";
import { default as overallJme4R24HFPMeta } from "/app/app/pages/dashboard/overall.vue?macro=true";
import { default as dashboardgCXiDF9kLpMeta } from "/app/app/pages/dashboard.vue?macro=true";
import { default as indexM6mJRhIbwTMeta } from "/app/app/pages/index.vue?macro=true";
import { default as indexK7BgUS1x7WMeta } from "/app/app/pages/level/index.vue?macro=true";
import { default as logine570OWMEzjMeta } from "/app/app/pages/login.vue?macro=true";
import { default as profile4G4rdWHhIYMeta } from "/app/app/pages/profile.vue?macro=true";
import { default as reset_passwordHyrR7daE2BMeta } from "/app/app/pages/reset_password.vue?macro=true";
import { default as indexl4gRJngItoMeta } from "/app/app/pages/room/index.vue?macro=true";
import { default as settingvmbyJ0C9byMeta } from "/app/app/pages/setting.vue?macro=true";
export default [
  {
    name: "alerts",
    path: "/alerts",
    component: () => import("/app/app/pages/alerts.vue").then(m => m.default || m),
    children: [
  {
    name: "alerts-assets",
    path: "assets",
    component: () => import("/app/app/pages/alerts/assets.vue").then(m => m.default || m)
  },
  {
    name: "alerts-energy",
    path: "energy",
    component: () => import("/app/app/pages/alerts/energy.vue").then(m => m.default || m)
  },
  {
    name: "alerts-environment",
    path: "environment",
    component: () => import("/app/app/pages/alerts/environment.vue").then(m => m.default || m)
  },
  {
    name: "alerts-overall",
    path: "overall",
    component: () => import("/app/app/pages/alerts/overall.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "analytics",
    path: "/analytics",
    component: () => import("/app/app/pages/analytics.vue").then(m => m.default || m),
    children: [
  {
    name: "analytics-assets",
    path: "assets",
    component: () => import("/app/app/pages/analytics/assets.vue").then(m => m.default || m)
  },
  {
    name: "analytics-eia",
    path: "eia",
    component: () => import("/app/app/pages/analytics/eia.vue").then(m => m.default || m)
  },
  {
    name: "analytics-ems",
    path: "ems",
    component: () => import("/app/app/pages/analytics/ems.vue").then(m => m.default || m)
  },
  {
    name: "analytics-hcm",
    path: "hcm",
    component: () => import("/app/app/pages/analytics/hcm.vue").then(m => m.default || m)
  },
  {
    name: "analytics-mfg",
    path: "mfg",
    component: () => import("/app/app/pages/analytics/mfg.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "building",
    path: "/building",
    component: () => import("/app/app/pages/building/index.vue").then(m => m.default || m)
  },
  {
    name: "configuration",
    path: "/configuration",
    component: () => import("/app/app/pages/configuration.vue").then(m => m.default || m),
    children: [
  {
    name: "configuration-alert",
    path: "alert",
    component: () => import("/app/app/pages/configuration/alert.vue").then(m => m.default || m)
  },
  {
    name: "configuration-device",
    path: "device",
    component: () => import("/app/app/pages/configuration/device.vue").then(m => m.default || m)
  },
  {
    name: "configuration-location",
    path: "location",
    component: () => import("/app/app/pages/configuration/location.vue").then(m => m.default || m)
  },
  {
    name: "configuration-user",
    path: "user",
    component: () => import("/app/app/pages/configuration/user.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "dashboard",
    path: "/dashboard",
    component: () => import("/app/app/pages/dashboard.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-assets",
    path: "assets",
    component: () => import("/app/app/pages/dashboard/assets.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-eia",
    path: "eia",
    component: () => import("/app/app/pages/dashboard/eia.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-ems",
    path: "ems",
    component: () => import("/app/app/pages/dashboard/ems.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-hcm",
    path: "hcm",
    component: () => import("/app/app/pages/dashboard/hcm.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-mfg",
    path: "mfg",
    component: () => import("/app/app/pages/dashboard/mfg.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-overall",
    path: "overall",
    component: () => import("/app/app/pages/dashboard/overall.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "index",
    path: "/",
    component: () => import("/app/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "level",
    path: "/level",
    component: () => import("/app/app/pages/level/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: logine570OWMEzjMeta || {},
    component: () => import("/app/app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "profile",
    path: "/profile",
    component: () => import("/app/app/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: "reset_password",
    path: "/reset_password",
    meta: reset_passwordHyrR7daE2BMeta || {},
    component: () => import("/app/app/pages/reset_password.vue").then(m => m.default || m)
  },
  {
    name: "room",
    path: "/room",
    component: () => import("/app/app/pages/room/index.vue").then(m => m.default || m)
  },
  {
    name: "setting",
    path: "/setting",
    component: () => import("/app/app/pages/setting.vue").then(m => m.default || m)
  }
]