export default defineNuxtRouteMiddleware(async (to) => {
  if (to.path === '/login') return;
  if (to.path === '/reset_password') return;

  if (import.meta.server) {
    const event = useRequestEvent()
    if (event?.context?.user) {
      const { id, name, email, createdAt, updatedAt } = event.context.user
      useState<any>('user', () => ({ id: id.toString(), name, email, createdAt, updatedAt }))
    } else {
      navigateTo('/login')
    }
  } else {
    const user = useState<any>('user')
    const token = useCookie<string>('token')
    try {
      if (!user.value) {
        user.value = await $fetch('/api/v1/user/account/me', {
          headers: {
            Authorization: `Bearer ${token.value}`
          }
        })
      }
    } catch (error) {
      navigateTo('/login')
    }
  }
})